import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ShoppingCartFactoringCreated from './components/1_ShoppingCartCreated';
import ShoppingCartFactoringQuoted from './components/2_ShoppingCartQuoted';
import ShoppingCartFactoringCancelled from './components/0_ShoppingCartCancelled';
import ShoppingCartFactoringAccepted from './components/3_ShoppingCartAccepted';
import ShoppingCartFactoringActive from './components/5_ShoppingCartActive';
import ShoppingCartFactoringCompleted from './components/6_ShoppingCartCompleted';
import CenteredCircularProgress from '../../../../../../../../../../../lib/designSystem/CircularProgress';
import { decrementPendingRequests } from '../../../../../../../../../../../services/redux/notificactionSlice';
import { rejectFactoringQuote } from '../../../../../../../../../../../lib/api/factoring/updateStatus';
import { validateCompanyTypes } from '../../../../../../../../../../../lib/functions/utils';
import { AuthState } from '../../../../../../../../../../../services/redux/authSlice';
import { getCompanyConfig } from '../../../../../../../../../../../lib/api/company';

export interface FactoringRequest {
  id: number;
  status: string;
  sender: string;
  recipient: string;
  validUntil: Date;
  paymentDate: Date;
  amount: number;
  extraData: boolean;
  relation?: any;
  businessId: number;
}

const componentsMap: any = {
  Creada: ShoppingCartFactoringCreated,
  Revisada: ShoppingCartFactoringCreated,
  'En revisión': ShoppingCartFactoringCreated,
  Cotizada: ShoppingCartFactoringQuoted,
  Rechazada: ShoppingCartFactoringCancelled,
  Aceptada: ShoppingCartFactoringAccepted,
  Activa: ShoppingCartFactoringActive,
  Completada: ShoppingCartFactoringCompleted,
};

const Summary = (props: any) => {
  const {
    shoppingCartType,
    openShoppingCart,
    setOpenShoppingCart,
    selectedRequest,
    setSelectedRequest,
    setSuccessOpen,
    setFailureOpen,
    setUp,
    executiveCreating,
    businessId,
    subProduct,
    setSubProduct,
    onlySimulation,
    openDatesSelection,
    setOpenDatesSelection,
    setBackwards,
    formData,
    setFormData,
    resetRequestToOriginal,
    selectedId,
    availableArrearTables,
  } = props;
  const [loading, setLoading] = useState(false);
  const [requestConfiguration, setRequestConfiguration] = useState({});

  const dispatch = useDispatch();

  const handleRejectQuote = async () => {
    setLoading(true);
    try {
      await rejectFactoringQuote(dispatch, {
        id: selectedRequest.quoteActive.id,
        status: 'REJECTED',
      });
      dispatch(decrementPendingRequests());
      await setUp();
      setSuccessOpen(`Se ha rechazado la solicitud ${selectedRequest.id}.`);
    } catch (error) {
      setFailureOpen('Lamentablemente ha ocurrido un error');
    }
    setLoading(false);
    setOpenShoppingCart(false);
  };

  const setExpensesHasIVA = async () => {
    try {
      const expensesHasIVAResponse = await getCompanyConfig(
        dispatch,
        'system_accounting_expensesHasIva',
      );
      const expensesHasIVA = expensesHasIVAResponse === '1';
      setRequestConfiguration({
        ...requestConfiguration,
        expensesHasIVA,
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setExpensesHasIVA();
  }, []);

  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const financingHub = validateCompanyTypes(user, 'FinancingHub');

  if (loading) return <CenteredCircularProgress type="dialog" />;

  const Component = componentsMap[shoppingCartType];

  return (
    <div>
      <Component
        subProduct={subProduct}
        setSubProduct={setSubProduct}
        openShoppingCart={openShoppingCart}
        setOpenShoppingCart={setOpenShoppingCart}
        selectedRequest={selectedRequest}
        setSelectedRequest={setSelectedRequest}
        setSuccessOpen={setSuccessOpen}
        setFailureOpen={setFailureOpen}
        inReview={shoppingCartType === 'En revisión'}
        setUp={setUp}
        setLoading={setLoading}
        executiveCreating={executiveCreating}
        businessId={businessId}
        onlySimulation={
          shoppingCartType === 'Creada' ? !financingHub : onlySimulation
        }
        openDatesSelection={openDatesSelection}
        setOpenDatesSelection={setOpenDatesSelection}
        setBackwards={setBackwards}
        formData={formData}
        setFormData={setFormData}
        handleRejectQuote={
          shoppingCartType === 'Cotizada' ? handleRejectQuote : undefined
        }
        requestConfiguration={requestConfiguration}
        resetRequestToOriginal={resetRequestToOriginal}
        selectedId={selectedId}
        availableArrearTables={availableArrearTables}
      />
    </div>
  );
};

export default Summary;
