import jsPDF from 'jspdf';
import { formatDate, formatMoney } from '../../../../../../lib/functions/utils';
import { Cursor } from '../../../../../../lib/classes/jsPdfCursor';

function getShortString(string: string, maxLength: number = 25) {
  return string.length > maxLength
    ? string.substring(0, maxLength) + '...'
    : string;
}

export const getImageDimensionsFromBase64 = (
  base64: string,
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.src = base64;
  });
};

export const generateSimulationCreditPDF = async (
  data: any,
  userName: string,
  imageBase64: string,
) => {
  const doc = new jsPDF();

  const addTitle = async () => {
    const imgX = 10;
    const imgY = 5;
    const imgHeight = 15;

    const { width: naturalWidth, height: naturalHeight } =
      await getImageDimensionsFromBase64(imageBase64);
    const aspectRatio = naturalWidth / naturalHeight;
    const imgWidth = imgHeight * aspectRatio;
    doc.addImage(imageBase64 as any, 'PNG', imgX, imgY, imgWidth, imgHeight);

    doc.setFontSize(16);
    doc.text('Simulación Crédito', 100, 15, { align: 'center' });

    doc.setFontSize(10);
    doc.text(`Fecha: ${new Date().toLocaleString()}`, 150, 10);
    doc.text(`Ejecutivo: ${userName}`, 150, 15);
  };

  const addSummary = (c: Cursor) => {
    c.writeTitle('Datos');

    c.writeLine('Rut Acreedor', true, 'col11');
    c.writeLine(`: ${data.businessIdentifier}`, false, 'col12');
    c.writeLine('Tipo de crédito', true, 'col21');
    c.writeLineAndNewLine(`: ${data.creditType}`, false, 'col22');

    c.writeLine('Nombre Acreedor', true, 'col11');
    c.writeLine(`: ${getShortString(data.businessName || '')}`, false, 'col12');
    c.writeLine('Tipo de interés', true, 'col21');
    c.writeLineAndNewLine(`: ${data.interestType}`, false, 'col22');

    c.newLine();
    c.writeTitle('Fechas');

    c.writeLine('Fecha inicio', true, 'col11');
    c.writeLine(`: ${data.startDate}`, false, 'col12');
    c.writeLine('Meses', true, 'col21');
    c.writeLineAndNewLine(`: ${data.months}`, false, 'col22');

    c.writeLine('Fecha primer pago', true, 'col11');
    c.writeLine(`: ${data.firstPaymentDate}`, false, 'col12');
    c.writeLine('Total de pagos', true, 'col21');
    c.writeLineAndNewLine(`: ${data.totalPayments}`, false, 'col22');

    c.writeLine('Fecha último pago', true, 'col11');
    c.writeLine(`: ${data.lastPaymentDate}`, false, 'col12');
    c.writeLine('Día de pago', true, 'col21');
    c.writeLineAndNewLine(`: ${data.paymentDay}`, false, 'col22');

    c.newLine();
    c.writeTitle('Montos y tasas');

    c.writeLine('Monto', true, 'col11');
    c.writeLine(`: ${formatMoney(data.totalAmount)}`, false, 'col12');
    c.writeLine('Interés mensual', true, 'col21');
    c.writeLineAndNewLine(`: ${data.rate}%`, false, 'col22');

    c.writeLine('Gastos operación', true, 'col11');
    c.writeLine(`: ${formatMoney(data.operationAmount)}`, false, 'col12');
    c.writeLine('Interés anual', true, 'col21');
    c.writeLineAndNewLine(`: ${data.anualRate}%`, false, 'col22');

    c.writeLine('Seguros', true, 'col11');
    c.writeLine(`: ${formatMoney(data.insuranceAmount)}`, false, 'col12');
    c.writeLine('Interés mora', true, 'col21');
    c.writeLineAndNewLine(`: ${data.arrearRate}%`, false, 'col22');

    c.writeLine('Total crédito', true, 'col11');
    c.writeLine(`: ${formatMoney(data.totalLoanAmount)}`, false, 'col12');
    c.writeLine('Interés mora anual', true, 'col21');
    c.writeLineAndNewLine(`: ${data.anualArrearRate}%`, false, 'col22');

    c.writeLine('Costo del crédito', true, 'col11');
    c.writeLine(`: ${formatMoney(data.totalCost)}`, false, 'col12');
    c.writeLine('CAE', true, 'col21');
    c.writeLineAndNewLine(`: ${data.cae.toFixed(2)}%`, false, 'col22');

    c.newLine();
  };

  const addWarrantiesTable = (c: Cursor) => {
    c.writeTitle('Garantías');
    c.writeTable(
      ['Tipo', 'Descripción', 'Valor'],
      data.warranties.map((warranty: any) => [
        warranty.type,
        warranty.description,
        formatMoney(warranty.value),
      ]),
    );
  };

  const addDocumentsTable = (c: Cursor) => {
    c.newLine();
    c.writeTitle('Cuadro de pagos');
    c.writeTable(
      [
        'N° Cuota',
        'Fecha Pago',
        'Saldo',
        'Cuota',
        'Interés',
        'Capital',
        'Amortización',
      ],
      data.amortizationSchedule.map((payment: any) => [
        payment.month,
        formatDate(payment.paymentDate, 'dd-mm-yyyy'),
        formatMoney(payment.balance),
        formatMoney(payment.amount),
        formatMoney(payment.interest),
        formatMoney(payment.principal),
        formatMoney(payment.totalAmortization),
      ]),
    );
  };

  const c = new Cursor(doc);
  await addTitle();
  addSummary(c);
  if (data.warranties.length > 0 && data.showWarranties) {
    addWarrantiesTable(c);
  }
  if (data.amortizationSchedule.length > 0 && data.showPaymentTable) {
    addDocumentsTable(c);
  }

  doc.save(`SIMULACION-CREDITO-${data.businessName}.pdf`);
};
